import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, ButtonToolbar, Col, Container, Modal, Row
} from 'reactstrap';
import { fetchEquipments, updateTagStatus } from 'services/equipmentService';
import {
  DARK_THEME, EquipmentTypesLabels, LIGHT_THEME, TagStatus, ToastConfigsSuccess, ToastConfigsError
} from 'shared/constants';
import { AssignedEquipment, Profile, State } from 'shared/types';
import LabelTextField from 'components/LabelTextField';
import EquipmentCard from '../Registration/components/EquipmentCard';
import Scanner from './components/Scanner';

interface CheckoutPage {
  theme: string;
}

const CheckoutPage = ({
  theme
}: CheckoutPage) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [equipmentList, setEquipmentList] = useState<AssignedEquipment[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [tag, setTag] = useState<AssignedEquipment | null>(null);
  const [isCheckIn, setIsCheckIn] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if (profile && userConfirmed && !isOpen) {
      fetchEquipments(profile?.id).then((equipments) => {
        setEquipmentList(equipments);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [profile, userConfirmed, isOpen]);

  useEffect(() => {
    if (userConfirmed) {
      setIsOpen(true);
    }
  }, [tag, userConfirmed]);

  const closeModal = () => {
    setError('');
    setIsOpen(false);
  };

  const changeTagStatus = () => {
    if (profile && userConfirmed) {
      updateTagStatus(tag?.qrCode || '', isCheckIn).then(() => {
        closeModal();
        toast.success(`Equipment ${isCheckIn ? 'Checked-in' : 'Checked-out'}`, ToastConfigsSuccess);
      }).catch((err: AxiosError) => {
        setError(err.response?.data.messages[0].message);
        toast.error(err.response?.data.messages[0].message, ToastConfigsError);
      });
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Check-in / Check-out</h3>
        </Col>
      </Row>
      <Row>
        <Scanner
          profile={profile}
          setProfile={setProfile}
          setEquipmentList={setEquipmentList}
          userConfirmed={userConfirmed}
          setUserConfirmed={setUserConfirmed}
          setTag={setTag}
          isCheckIn={isCheckIn}
          setIsCheckIn={setIsCheckIn}
        />
      </Row>
      <Modal isOpen={isOpen} toggle={closeModal} className={theme}>
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={closeModal}
          />
          <h4 className="bold-text  modal__title">Confirm Equipment Information</h4>
        </div>
        <div className="form form--horizontal">
          <LabelTextField
            label="QR Code"
            text={tag?.qrCode || ''}
          />
          <LabelTextField
            label="Type"
            text={EquipmentTypesLabels[tag?.type || 0]}
          />
          <LabelTextField
            label="Status"
            text={TagStatus[tag?.status || 0]}
          />
          <LabelTextField
            label="Modified"
            text={tag?.modified || ''}
          />
          <LabelTextField
            label="Description"
            text={tag?.description || ''}
          />
          <ButtonToolbar className="modal__footer">
            <Button className="confirm" color="primary" onClick={changeTagStatus}>
              Confirm
            </Button>
            <Button className="cancel" onClick={closeModal}>Cancel</Button>{' '}
          </ButtonToolbar>
          <span className="form__form-group-error">{error}</span>
        </div>
      </Modal>
      <Row>
        {equipmentList.map((equipment) => (<EquipmentCard key={equipment.qrCode} equipment={equipment} />))}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: State) => ({
  theme: state.layout.isDarkTheme ? DARK_THEME : LIGHT_THEME
});

export default connect(mapStateToProps)(CheckoutPage);
