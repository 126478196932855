import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Collapse } from 'reactstrap';

interface SidebarCategoryProps {
  title: string;
  icon?: string;
  children?: ReactNode;
}

const SidebarCategory = ({ title, icon, children }: SidebarCategoryProps) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };
  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': collapse
  });

  return (
    <div className={categoryClass}>
      <button
        type="button"
        className="sidebar__link sidebar__category"
        onClick={toggle}
      >
        {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
        <p className="sidebar__link-title">{title}</p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          {children}
        </ul>
      </Collapse>
    </div>
  );
};

export default SidebarCategory;
