import { SeatsioIsOpenAction, SET_SEATSIOISOPEN_ACTION } from '../actions/seatsioActions';
import initialState from './initialState';

export default function (state = initialState.seatsioIsOpen, action: SeatsioIsOpenAction) {
  switch (action.type) {
    case SET_SEATSIOISOPEN_ACTION:
      return !state;
    default:
      return state;
  }
}
