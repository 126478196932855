import classNames from 'classnames';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { LayoutState, State } from 'shared/types';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  changeThemeAction,
  LayoutAction
} from 'redux/actions/layoutActions';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

interface LayoutProps {
  sidebar: LayoutState;
  sidebarVisibilityChange: VoidFunction;
  mobileSidebarVisibilityChange: VoidFunction;
  changeTheme: VoidFunction;
}

const Layout = ({
  sidebar,
  sidebarVisibilityChange,
  mobileSidebarVisibilityChange,
  changeTheme
}: LayoutProps) => {
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse
  });

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibilityChange}
        changeSidebarVisibility={sidebarVisibilityChange}
        changeTheme={changeTheme}
      />
      <Sidebar
        sidebar={sidebar}
        changeMobileSidebarVisibility={mobileSidebarVisibilityChange}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({ sidebar: state.layout });

const mapDispatchToProps = (dispatch: Dispatch<LayoutAction>) => ({
  sidebarVisibilityChange: () => dispatch(changeSidebarVisibility()),
  mobileSidebarVisibilityChange: () => dispatch(changeMobileSidebarVisibility()),
  changeTheme: () => dispatch(changeThemeAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
