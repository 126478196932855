import SelectInput from 'components/SelectInput';
import {
  Field,
  Form,
  withFormik,
  FormikProps
} from 'formik';
import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import {
  Button,
  ButtonToolbar,
  Modal,
  ButtonGroup
} from 'reactstrap';
import { toast } from 'react-toastify';
import { fetchTagIsAvailable, createEquipment } from 'services/equipmentService';
import {
  EquipmentTypes, ToastConfigsSuccess, ToastConfigsError
} from 'shared/constants';
import { OptionSelect, EquipmentFormValues } from 'shared/types';

interface EquipmentForm {
  isOpen: boolean;
  closeModal: VoidFunction;
  theme: string;
  profileId: number;
}

const TAG_IS_INVALID = 'Tag is already taken or invalid!';
const WEBCAM_ERROR = 'There\'s a problem with the webcam or the tag!';
const EQUIP_CREATED = 'Equipment Added!';
const ERROR_EQUIP = 'Error Adding Equipment!';

const EquipmentForm = ({
  isOpen,
  closeModal,
  theme,
  setFieldValue,
  resetForm,
  values,
  isSubmitting,
  status,
  setStatus
}: EquipmentForm & FormikProps<EquipmentFormValues>) => {
  const [manualQr, setManualQr] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleScan = (data: string | null) => {
    if (data) {
      fetchTagIsAvailable(data).then((isAvailable) => {
        if (isAvailable) {
          setStatus('');
          setFieldValue('qrCode', data);
        } else {
          setStatus(TAG_IS_INVALID);
          toast.error(TAG_IS_INVALID, ToastConfigsError);
        }
      }).catch((err) => {
        console.error(err);
        setStatus(TAG_IS_INVALID);
        toast.error(TAG_IS_INVALID, ToastConfigsError);
      });
    }
  };

  const handleError = (err: string) => {
    console.error(err);
    setStatus(WEBCAM_ERROR);
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleScan(manualQr);
  };

  const setSelectInputValue = (input: OptionSelect) => {
    setFieldValue('type', input.value.toString());
  };

  useEffect(() => {
    if (!isSubmitting) handleClose();
    // eslint-disable-next-line
  }, [isSubmitting]);

  useEffect(() => {
    setIsDisabled(!(values.description && values.qrCode && values.type));
  }, [values]);

  return (
    <Modal isOpen={isOpen} toggle={handleClose} className={theme}>
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={handleClose}
        />
        <h4 className="bold-text  modal__title">Equipment Form</h4>
      </div>
      {values.qrCode !== '' ? (
        <Form className="form form--horizontal">
          <div className="form__form-group">
            <span className="form__form-group-label">QR Code: </span>
            <div className="form__form-group-field">
              <Field name="qrCode" component="input" disabled />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Type: </span>
            <div className="form__form-group-field">
              <Field
                name="type"
                component={SelectInput}
                onChange={setSelectInputValue}
                default={null}
                value={values.type && EquipmentTypes[values.type]}
                options={EquipmentTypes}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Description: </span>
            <div className="form__form-group-field">
              <Field
                name="description"
                placeholder="Visual Description"
                component="input"
              />
            </div>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="confirm" color="primary" type="submit" disabled={isDisabled}>
              Save
            </Button>
            <Button className="cancel" onClick={handleClose}>Cancel</Button>{' '}
          </ButtonToolbar>
          <span className="form__form-group-error">{status}</span>
        </Form>
      ) : (
        <>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%', padding: '0 0 10px 0' }}
          />
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">QR Code: </span>
              <div className="form__form-group-field">
                <input
                  name="manualQrCode"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setManualQr(e.target.value);
                  }}
                  onKeyPress={onEnterPress}
                />
                <ButtonToolbar style={{ marginTop: '0', marginLeft: '7px' }}>
                  <ButtonGroup className="btn-group--icons">
                    <Button outline onClick={() => handleScan(manualQr)}>
                      <span className="lnr lnr-chevron-right" />
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
              <div className="form__form-fullwidth">
                <span className="form__form-group-error">{status}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withFormik<EquipmentForm, EquipmentFormValues>({
  mapPropsToValues({ profileId }) {
    return {
      profileId,
      qrCode: '',
      type: null,
      description: ''
    };
  },
  handleSubmit(values: EquipmentFormValues, { setSubmitting, setStatus }) {
    createEquipment(values).then((created) => {
      if (created) {
        setSubmitting(false);
        setStatus('');
        toast.success(EQUIP_CREATED, ToastConfigsSuccess);
      }
    }).catch((err) => {
      console.error(err);
      setStatus(ERROR_EQUIP);
      toast.success(ERROR_EQUIP, ToastConfigsError);
    });
  }
})(EquipmentForm);
