import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button
} from 'reactstrap';
import { match } from 'react-router-dom';
import { fetchProfile } from 'services/userService';
import { APIProfile, Profile } from 'shared/types';
import { history } from 'App/router';
import LabelTextField from 'components/LabelTextField';
import { USERS_ROUTE } from 'shared/routes';

interface UserUrl {
  id: string;
}

interface UserPage {
  match: match<UserUrl>;
}

function getProfile(id: string): Promise<APIProfile> {
  return fetchProfile(id);
}
// eslint-disable-next-line no-shadow
const UserPage = ({ match }: UserPage) => {
  const { id } = match.params;
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    getProfile(id).then((userProfile: APIProfile) => {
      setProfile({
        username: userProfile.username,
        fullname: `${userProfile.first_name} ${userProfile.last_name}`,
        email: userProfile.email,
        id: userProfile.id
      });
    });
  }, [id]);

  const resetUser = () => {
    history.push(USERS_ROUTE);
  };

  return (
    <Container>
      {profile && (
        <>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{profile.username}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">User Profile</h5>
                  </div>
                  <div className="form form--horizontal">
                    <LabelTextField
                      label="Profile ID"
                      text={profile.id.toString()}
                    />
                    <LabelTextField
                      label="Username"
                      text={profile.username}
                    />
                    <LabelTextField
                      label="Name"
                      text={profile.fullname}
                    />
                    <LabelTextField
                      label="Email"
                      text={profile.email}
                    />
                  </div>
                  <Button color="primary" onClick={resetUser}>
                    Search New User
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default UserPage;
