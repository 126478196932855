import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DEFAULT_ROUTE, LOGIN_ROUTE } from 'shared/routes';
import { hasAccess } from 'services/authService';

interface RouteProps {
  component: any;
  path: string;
  isAuthenticated: boolean;
  groups: string[];
  exact?: boolean;
}

export const ProtectedRoute = ({
  component,
  exact,
  path,
  isAuthenticated,
  groups
}: RouteProps) => {
  const routeComponent = () => (isAuthenticated && hasAccess(groups) ? (
    React.createElement(component, { exact, path, groups })
  ) : (
    <Redirect to={{ pathname: LOGIN_ROUTE }} />
  ));
  return <Route render={routeComponent} />;
};

interface UnProtectedRoute {
  component: any;
  path: string;
  isAuthenticated: boolean;
  exact?: boolean;
}

export const UnProtectedRoute = ({
  component,
  exact,
  path,
  isAuthenticated,
  groups
}: RouteProps) => {
  const routeComponent = () => {
    return !isAuthenticated || !hasAccess(groups) ? (
      React.createElement(component, { exact, path })
    ) : (
      <Redirect to={{ pathname: DEFAULT_ROUTE }} />
    );
  };
  return <Route render={routeComponent} />;
};
