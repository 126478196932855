import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkProps {
  title: string;
  route: string;
  onClick?: () => void;
  icon?: string;
}

const SidebarLink = ({
  title,
  icon,
  route,
  onClick
}: SidebarLinkProps) => (
  <NavLink to={route} onClick={onClick} activeClassName="sidebar__link-active">
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">{title}</p>
    </li>
  </NavLink>
);

export default SidebarLink;
