const prod = {
  API_URL: 'https://lanets.ca',
};

const dev = {
  API_URL: 'http://localhost:8080'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

export const SEATSIO_DESIGNER_KEY = '8af1d845-1e53-4018-98e3-803791d5aa56';
export const SEATSIO_CHART_KEY = '01ef9bb2-7d3b-7aaa-7eed-67e06a83aa2e';
