export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export type CHANGE_SIDEBAR_VISIBILITY = typeof CHANGE_SIDEBAR_VISIBILITY;
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export type CHANGE_MOBILE_SIDEBAR_VISIBILITY = typeof CHANGE_MOBILE_SIDEBAR_VISIBILITY;
export const CHANGE_THEME_ACTION = 'CHANGE_THEME_ACTION';
export type CHANGE_THEME_ACTION = typeof CHANGE_THEME_ACTION;

type LayoutActionType =
  | CHANGE_SIDEBAR_VISIBILITY
  | CHANGE_MOBILE_SIDEBAR_VISIBILITY
  | CHANGE_THEME_ACTION;

export interface LayoutAction {
  type: LayoutActionType;
}

export const changeSidebarVisibility = (): LayoutAction => ({
  type: CHANGE_SIDEBAR_VISIBILITY
});

export const changeMobileSidebarVisibility = (): LayoutAction => ({
  type: CHANGE_MOBILE_SIDEBAR_VISIBILITY
});

export const changeThemeAction = (): LayoutAction => ({
  type: CHANGE_THEME_ACTION
});
