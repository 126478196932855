import { createBrowserHistory } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import {
  CHECKOUT_ROUTE,
  DEFAULT_ROUTE,
  FLOORPLAN_ROUTE,
  INCIDENT_ROUTE,
  LOGIN_ROUTE, REGISTRATION_ROUTE,
  TOURNAMENTS_ROUTE,
  USERID_ROUTE,
  USERS_ROUTE
} from 'shared/routes';
import { State } from 'shared/types';
import { isStaff, isCashier } from 'services/authService';
import Layout from '../Layout/index';
import CheckoutPage from '../Pages/Checkout';
import FloorplanPage from '../Pages/Floorplan';
import IncidentPage from '../Pages/Incident';
import LogIn from '../Pages/LogIn/index';
import RegistrationPage from '../Pages/Registration';
import TournamentsPage from '../Pages/Tournaments';
import UserSearchPage from '../Pages/Users';
import UserPage from '../Pages/Users/UserPage';
import MainWrapper from './mainWrapper';
import { ProtectedRoute, UnProtectedRoute } from './routes';

export const history = createBrowserHistory();

interface Pages {
  groups: string[];
}

const Pages = ({
  groups
}: Pages) => {
  return (
    <Switch>
      {isStaff(groups)
        && <Route path={USERS_ROUTE} component={UserSearchPage} exact />}
      {false && <Route path={TOURNAMENTS_ROUTE} component={TournamentsPage} />}
      {false && <Route path={INCIDENT_ROUTE} component={IncidentPage} />}
      {isStaff(groups) && <Route path={FLOORPLAN_ROUTE} component={FloorplanPage} />}
      {isCashier(groups)
        && <Route path={CHECKOUT_ROUTE} component={CheckoutPage} />}
      {isCashier(groups)
        && <Route path={REGISTRATION_ROUTE} component={RegistrationPage} />}
      {isStaff(groups)
        && <Route path={USERID_ROUTE} component={UserPage} />}
    </Switch>
  );
};
interface WrappedRoutes {
  groups: string[];
}

const WrappedRoutes = ({
  groups
}: WrappedRoutes) => {
  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Route path={DEFAULT_ROUTE} render={() => <Pages groups={groups} />} />
      </div>
    </>
  );
};

interface Router {
  isAuthenticated: boolean;
  groups: string[];
}

const Router = ({
  isAuthenticated, groups
}: Router) => {
  return (
    <BrowserRouter history={history}>
      <MainWrapper>
        <main>
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              component={WrappedRoutes}
              isAuthenticated={isAuthenticated}
              groups={groups}
            />
            <UnProtectedRoute
              exact
              path={LOGIN_ROUTE}
              component={LogIn}
              isAuthenticated={isAuthenticated}
              groups={groups}
            />
            <ProtectedRoute
              path={DEFAULT_ROUTE}
              component={WrappedRoutes}
              isAuthenticated={isAuthenticated}
              groups={groups}
            />
          </Switch>
        </main>
      </MainWrapper>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: State) => ({
  isAuthenticated: state.user.isAuthenticated,
  groups: state.user.groups
});

export default connect(mapStateToProps)(Router);
