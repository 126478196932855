import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { isUserAuthenticated } from 'services/authService';
import 'assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import SeatsioDesigner from 'components/Seatsio/SeatsioDesigner';
import { SEATSIO_CHART_KEY, SEATSIO_DESIGNER_KEY } from 'config';
import { State } from 'shared/types';
import Router from './router';

interface App {
  seatsioIsOpen: boolean;
}

const App = ({
  seatsioIsOpen
}: App) => {
  useEffect(() => {
    isUserAuthenticated();
  }, []);

  return (
    seatsioIsOpen
      ? (
        <SeatsioDesigner
          designerKey={SEATSIO_DESIGNER_KEY}
          chartKey={SEATSIO_CHART_KEY}
          className="seatsio"
        />
      ) : (
        <BrowserRouter>
          <div>
            <Router />
            <ToastContainer />
          </div>
        </BrowserRouter>
      )
  );
};

const mapStateToProps = (state: State) => ({
  seatsioIsOpen: state.seatsioIsOpen
});

export default connect(mapStateToProps)(App);
