import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import {
  Col,
  Card,
  CardBody,
  Button,
  Row,
  ButtonToolbar,
  ButtonGroup
} from 'reactstrap';
import { fetchTagDetails } from 'services/equipmentService';
import { Profile, AssignedEquipment } from 'shared/types';
import SwitchButton from 'components/SwitchButton';
import LabelTextField from 'components/LabelTextField';
import { toast } from 'react-toastify';
import { ToastConfigsError } from 'shared/constants';

interface Scanner {
  profile: Profile | null;
  setProfile: (profile: Profile | null) => void;
  setEquipmentList: (equipmentList: AssignedEquipment[]) => void;
  userConfirmed: boolean;
  setUserConfirmed: (isConfirmed: boolean) => void;
  setTag: (tag: AssignedEquipment) => void;
  setIsCheckIn: (isCheckIn: boolean) => void;
  isCheckIn: boolean;
}

const WRONG_USER = 'THIS TAG DOESN\'T BELONG TO THIS PERSON!!!';
const NO_TAG = 'This tag does\'t exists.';
const CONFIRM_USER = 'Confirm User!';

const Scanner = ({
  profile,
  setProfile,
  setEquipmentList,
  userConfirmed,
  setUserConfirmed,
  setTag,
  setIsCheckIn,
  isCheckIn
}: Scanner) => {
  const [status, setStatus] = useState('');
  const [manualQr, setManualQr] = useState('');

  const handleScan = (data: string | null) => {
    if (data) {
      fetchTagDetails(data).then((tag) => {
        if (profile === null) {
          setProfile(tag.owner);
          setManualQr('');
          setTag({
            qrCode: tag.qrCode,
            status: tag.status,
            description: tag.equipment.description,
            type: tag.equipment.type,
            modified: tag.modified
          });
          setStatus('');
        } else if (profile?.id === tag.owner.id && userConfirmed) {
          setTag({
            qrCode: tag.qrCode,
            status: tag.status,
            description: tag.equipment.description,
            type: tag.equipment.type,
            modified: tag.modified
          });
          setManualQr('');
          setStatus('');
        } else if (profile && !userConfirmed) {
          setStatus(CONFIRM_USER);
          toast.error(CONFIRM_USER, ToastConfigsError);
        } else {
          setStatus(WRONG_USER);
          toast.error(WRONG_USER, ToastConfigsError);
        }
      }).catch(() => {
        setStatus(NO_TAG);
        toast.error(NO_TAG, ToastConfigsError);
      });
    }
  };

  const handleError = (err: string) => {
    console.error(err);
  };

  const resetUser = () => {
    setProfile(null);
    setEquipmentList([]);
    setUserConfirmed(false);
    setManualQr('');
  };

  const switchCheckout = () => {
    setIsCheckIn(!isCheckIn);
  };

  const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleScan(manualQr);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Row>
            <Col md={3}>
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ padding: '0 0 10px 0' }}
              />
              <div className="form form--horizontal">
                <div className="form__form-group">
                  <span className="form__form-group-label">QR Code: </span>
                  <div className="form__form-group-field">
                    <input
                      name="manualQrCode"
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setManualQr(e.target.value);
                      }}
                      onKeyPress={onEnterPress}
                      value={manualQr}
                    />
                    <ButtonToolbar style={{ marginTop: '0', marginLeft: '7px' }}>
                      <ButtonGroup className="btn-group--icons">
                        <Button outline onClick={() => handleScan(manualQr)}>
                          <span className="lnr lnr-chevron-right" />
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
                {status && <span className="form__form-group-error">{status}</span>}
              </div>
            </Col>
            <Col md={9}>
              <SwitchButton
                name="Checkout"
                label="Out"
                label2="In"
                onClick={switchCheckout}
                style={{ width: 'auto' }}
              />
              {profile ? (
                <>
                  <div className="card__title">
                    <h5 className="bold-text">Profile Information</h5>
                  </div>
                  <div className="form form--horizontal">
                    <LabelTextField label="Profile ID" text={profile.id.toString()} />
                    <LabelTextField label="Name" text={profile.fullname} />
                  </div>
                  <ButtonToolbar>
                    {!userConfirmed && (
                    <Button
                      color="primary"
                      onClick={() => {
                        setUserConfirmed(true);
                      }}
                    >
                      Confirm
                    </Button>
                    )}
                    <Button onClick={resetUser}>
                      Search New User
                    </Button>
                  </ButtonToolbar>
                </>
              ) : (
                <div className="card__title">
                  <h5 className="bold-text">Scan Tag to get user</h5>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Scanner;
