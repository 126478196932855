import { Tokens, Layout } from 'shared/types';

export const loadDarkTheme = () => {
  try {
    const serializedDarkTheme = localStorage.getItem('isDarkTheme');
    if (!serializedDarkTheme) {
      return false;
    }
    return JSON.parse(serializedDarkTheme) as boolean;
  } catch (error) {
    return false;
  }
};

export const saveDarkTheme = (layout: Layout): void => {
  try {
    const serializedDarkTheme = JSON.stringify(layout.isDarkTheme);
    localStorage.setItem('isDarkTheme', serializedDarkTheme);
  } catch (error) {
    console.error(error);
  }
};

export const getTokens = (): Tokens | null => {
  try {
    const serializedTokens = localStorage.getItem('tokens');
    if (!serializedTokens) return null;
    return JSON.parse(serializedTokens);
  } catch (err) {
    return null;
  }
};

export const getAccessToken = (): string | null => {
  const tokens = getTokens();
  return tokens ? tokens.access : null;
};

export const saveTokens = (tokens: Tokens) => {
  try {
    const serializedTokens = JSON.stringify(tokens);
    localStorage.setItem('tokens', serializedTokens);
  } catch (err) {
    console.error(err);
  }
};

export const deleteTokens = () => {
  try {
    localStorage.removeItem('tokens');
  } catch (err) {
    console.error(err);
  }
};
