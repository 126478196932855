import classNames from 'classnames';
import React from 'react';
import { LayoutState } from 'shared/types';
import SidebarContent from './SidebarContent';

interface LayoutProps {
  changeMobileSidebarVisibility: () => void;
  sidebar: LayoutState;
}

const Sidebar = ({ changeMobileSidebarVisibility, sidebar }: LayoutProps) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse
  });

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <SidebarContent />
      </div>
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent onClick={changeMobileSidebarVisibility} />
      </div>
    </div>
  );
};

export default Sidebar;
