import {
  CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  CHANGE_SIDEBAR_VISIBILITY,
  CHANGE_THEME_ACTION,
  LayoutAction
} from '../actions/layoutActions';
import initialState from './initialState';

export default function (state = initialState.layout, action: LayoutAction) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
      return { ...state, show: !state.show };
    case CHANGE_THEME_ACTION:
      return { ...state, isDarkTheme: !state.isDarkTheme };
    default:
      return state;
  }
}
