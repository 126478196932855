import {
  EquipmentFormValues,
  AssignedEquipment,
  APITag,
} from 'shared/types';
import services from '.';

interface IsAvailable {
  is_available: boolean;
}

const formatDate = (date: string) => {
  const d = new Date(date);
  const days = d.getDate();
  const months = d.getMonth();
  const hours = d.getHours();
  const minutes = d.getMinutes();

  return `${days > 9 ? days : `0${days}`}/${months > 9 ? months : `0${months}`} 
  - ${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export const fetchTagIsAvailable = async (tag: string) => {
  const response = await services.get(`/admission/api/v1/tag/${tag}/is_available`);
  return (response.data as IsAvailable).is_available;
};

export const createEquipment = async (data: EquipmentFormValues) => {
  const response = await services.post('/admission/api/v1/register/', {
    // eslint-disable-next-line @typescript-eslint/camelcase
    profile_id: data.profileId.toString(),
    tag: data.qrCode,
    type: data.type ? data.type.toString() : '',
    desc: data.description
  });
  return response.status === 201;
};

export const fetchEquipments = async (id: number) => {
  const response = await services.get(`/admission/api/v1/profile/${id}/equipment_list`);

  const equipments: AssignedEquipment[] = response.data.map((tag: APITag) => ({
    qrCode: tag.serial,
    type: tag.equipment.category,
    description: tag.equipment.description,
    status: tag.status,
    modified: formatDate(tag.modified)
  }));
  return equipments;
};

export const fetchTagDetails = async (tag: string) => {
  const response = await services.get(`/admission/api/v1/tag/${tag}`);
  const tagDetails = {
    qrCode: response.data.tag.serial,
    status: response.data.tag.status,
    modified: formatDate(response.data.tag.modified),
    owner: {
      id: response.data.tag.equipment.owner.id,
      username: response.data.tag.equipment.owner.username as string,
      fullname: `${response.data.tag.equipment.owner.first_name} ${response.data.tag.equipment.owner.last_name}`,
      email: response.data.tag.equipment.owner.email
    },
    equipment: {
      description: response.data.tag.equipment.description,
      type: response.data.tag.equipment.category,
    }
  };
  return tagDetails;
};

export const updateTagStatus = async (tag: string, isCheckIn: boolean) => {
  const status = isCheckIn ? '1' : '0';
  const response = await services.patch(
    `/admission/api/v1/tag/${tag}`, { status }
  );
  return response;
};
