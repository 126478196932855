import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { fetchEquipments } from 'services/equipmentService';
import { Profile, AssignedEquipment, State } from 'shared/types';
import { DARK_THEME, LIGHT_THEME } from 'shared/constants';
import AddEquipment from './components/AddEquipment';
import EquipmentCard from './components/EquipmentCard';
import EquipmentForm from './components/EquipmentForm';
import ProfileNumberSearch from './components/ProfileNumberSearch';
import CreateProfileForm from './components/CreateProfileForm';

interface RegistrationPage {
  theme: string;
  profileId: string;
}

const RegistrationPage = ({ theme, profileId }: RegistrationPage) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [createProfile, setCreateProfile] = useState(false);
  const [equipmentList, setEquipmentList] = useState<AssignedEquipment[]>([]);

  const addItem = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openCreateProfile = () => {
    setCreateProfile(true);
  };

  const closeCreateUser = () => {
    setCreateProfile(false);
  };

  useEffect(() => {
    if (profile) {
      fetchEquipments(profile.id).then((equipments) => {
        setEquipmentList(equipments);
      }).catch((err) => {
        console.error(err);
      });
    } else {
      setEquipmentList([]);
    }
  }, [profile, isOpen]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Equipment Registration</h3>
        </Col>
      </Row>
      <Row>
        <ProfileNumberSearch
          profile={profile}
          setProfile={setProfile}
          createProfile={openCreateProfile}
          profileId={profileId}
        />
        {profile && <AddEquipment addItem={addItem} />}
      </Row>
      {createProfile && (
        <CreateProfileForm
          isOpen={createProfile}
          closeModal={closeCreateUser}
          profile={profile}
          theme={theme}
        />
      )}
      {profile && (
        <>
          <Row>
            <Col md={12}>
              <h4 className="page-title">Equipment</h4>
            </Col>
          </Row>
          <Row>
            {equipmentList.map((equipment) => (
              <EquipmentCard key={equipment.qrCode} equipment={equipment} />
            ))}
            <EquipmentForm
              isOpen={isOpen}
              closeModal={closeModal}
              profileId={profile.id}
              theme={theme}
            />
          </Row>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state: State) => ({
  theme: state.layout.isDarkTheme ? DARK_THEME : LIGHT_THEME,
  profileId: state.profileId
});

export default connect(mapStateToProps)(RegistrationPage);
