import { UserStateAction, AUTH_ACTION } from '../actions/authActions';
import initialState from './initialState';

export default function (state = initialState.user, action: UserStateAction) {
  switch (action.type) {
    case AUTH_ACTION:
      return {
        ...state,
        isAuthenticated: action.data.isAuthenticated,
        groups: action.data.groups
      };
    default:
      return state;
  }
}
