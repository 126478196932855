import React from 'react';
import { connect } from 'react-redux';
import { State } from 'shared/types';
import {
  CHECKOUT_ROUTE,
  FLOORPLAN_ROUTE,
  INCIDENT_ROUTE,
  REGISTRATION_ROUTE,
  TOURNAMENTS_ROUTE,
  USERS_ROUTE
} from 'shared/routes';
import { isStaff, isCashier } from 'services/authService';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

interface SidebarContentProps {
  onClick?: () => void;
  groups: string[];
}

const SidebarContent = ({ onClick, groups }: SidebarContentProps) => (
  <div className="sidebar__content">
    <div className="sidebar__block">
      {isStaff(groups) && (
      <SidebarLink
        title="Users"
        route={USERS_ROUTE}
        onClick={onClick}
        icon="users"
      />
      )}
      {false && (
      <SidebarLink
        title="Tournaments"
        route={TOURNAMENTS_ROUTE}
        onClick={onClick}
        icon="rocket"
      />
      )}
      {false && (
      <SidebarLink
        title="Incident"
        route={INCIDENT_ROUTE}
        onClick={onClick}
        icon="envelope"
      />
      )}
      {isStaff(groups) && (
      <SidebarLink
        title="Floorplan"
        route={FLOORPLAN_ROUTE}
        onClick={onClick}
        icon="map"
      />
      )}
    </div>
    {isCashier(groups)
      && (
      <div className="sidebar__block">
        <SidebarCategory title="Equipment" icon="screen">
          <>
            <SidebarLink
              title="Registration"
              route={REGISTRATION_ROUTE}
              onClick={onClick}
            />
            <SidebarLink
              title="Check-in / Check-out"
              route={CHECKOUT_ROUTE}
              onClick={onClick}
            />
          </>
        </SidebarCategory>
      </div>
      )}
  </div>
);

const mapStateToProps = (state: State) => ({
  groups: state.user.groups
});

export default connect(mapStateToProps)(SidebarContent);
