import services, { getRequestHeaders } from '.';
import {
  PublicProfile, User, APIProfile, CreateProfileFormValues
} from '../shared/types';

interface UserDto {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export const fetchUsers = async (keyword?: string) => {
  try {
    const response = await services.get(
      `/accounts/api/v1/profile/search?search_query=${keyword}`,
      getRequestHeaders()
    );
    return (response.data as UserDto[]).map((user) => ({
      ...user,
      fullname: `${user.first_name} ${user.last_name}`
    })) as User[];
  } catch (err) {
    return [];
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await services.get(
      '/accounts/api/v1/users/current/',
      getRequestHeaders()
    );

    return response.data as PublicProfile;
  } catch (err) {
    return err;
  }
};

export const fetchProfile = async (id: string) => {
  const response = await services.get(
    `/accounts/api/v1/profile/${id}`,
    getRequestHeaders()
  );

  return response.data as APIProfile;
};

export const createProfile = async (user: CreateProfileFormValues) => {
  const response = await services.post(
    'accounts/api/v1/profile/',
    {
      ...user,
      first_name: user.firstname,
      last_name: user.lastname
    }
  );
  return (response.data as APIProfile).id;
};
