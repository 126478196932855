import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

interface SearchBar {
  type: 'text' | 'number';
  name?: string;
  placeholder?: string;
  onValueChange: (value: string) => void;
  withIcon?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<Element>) => void;
}

const SearchBar = ({
  name, placeholder, onValueChange, withIcon, onKeyPress, type
}: SearchBar) => (
  <div className="form__form-group-field">
    <input
      className="form__form-group-input-wrap"
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={(e) => onValueChange(e.target.value)}
      onKeyPress={onKeyPress}
    />
    {withIcon && (
      <div className="form__form-group-icon">
        <MagnifyIcon />
      </div>
    )}
  </div>
);

export default SearchBar;
