import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

interface AddEquipment {
  addItem: VoidFunction;
}

const AddEquipment = ({ addItem }: AddEquipment) => {
  return (
    <Col md={3}>
      <Card onClick={addItem}>
        <CardBody className="card__add-item ">
          <span>&#43;</span>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AddEquipment;
