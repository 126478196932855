import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col
} from 'reactstrap';
import { fetchProfile } from 'services/userService';
import { Profile, APIProfile } from 'shared/types';
import SearchBar from 'components/SearchBar';
import LabelTextField from 'components/LabelTextField';
import store from 'App/store';
import { setProfileIdAction } from 'redux/actions/profileActions';

interface ProfileNumberSearch {
  profile: Profile | null;
  setProfile: (profile: Profile | null) => void;
  createProfile: VoidFunction;
  profileId: string;
}

function getProfile(profileNumb: string): Promise<APIProfile> {
  return fetchProfile(profileNumb);
}

const PROFILE_NUMBER_ERROR = 'Profile number is invalid!';

const ProfileNumberSearch = ({
  profile, setProfile, createProfile, profileId
}: ProfileNumberSearch) => {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (search) {
      getProfile(search).then((userProfile) => {
        if (userProfile) {
          setError('');
          setProfile({
            id: userProfile.id,
            username: userProfile.username,
            fullname: `${userProfile.first_name} ${userProfile.last_name}`,
            email: userProfile.email
          });
        }
      }).catch((err) => {
        console.error(err);
        setError(PROFILE_NUMBER_ERROR);
      });
    } else if (profileId) {
      setSearch(profileId);
      store.dispatch(setProfileIdAction(''));
    }
  }, [search, setProfile, profileId]);

  const resetUser = () => {
    setValue('');
    setSearch('');
    setProfile(null);
    setIsDisabled(true);
  };

  const onEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') setSearch(value);
  };

  return (
    <Col md={9}>
      <Card>
        <CardBody>
          {profile ? (
            <div>
              <div className="card__title">
                <h5 className="bold-text">User Information</h5>
              </div>
              <div className="form form--horizontal">
                <LabelTextField label="Profile ID" text={profile.id.toString()} />
                <LabelTextField label="Username" text={profile.username} />
                <LabelTextField label="Name" text={profile.fullname} />
                <LabelTextField label="Email" text={profile.email} />
                <Button color="primary" onClick={resetUser}>
                  Search New User
                </Button>
              </div>
            </div>
          ) : (
            <div className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">Profile ID: </span>
                <div className="form__form-group-field">
                  <SearchBar
                    type="number"
                    name="profileNumb"
                    placeholder="Enter Profile ID"
                    onValueChange={(input: string) => {
                      setIsDisabled(!input);
                      setValue(input);
                    }}
                    onKeyPress={onEnterPress}
                    withIcon
                  />
                </div>
                <span className="form__form-group-error left">{error}</span>
              </div>
              <Button
                color="primary"
                onClick={() => {
                  setSearch(value);
                }}
                disabled={isDisabled}
              >
                Get Profile
              </Button>
              <Button
                onClick={() => {
                  createProfile();
                }}
              >
                Create User
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileNumberSearch;
