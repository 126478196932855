import { applyMiddleware, combineReducers, createStore } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  layoutReducer,
  userReducer,
  profileReducer,
  seatsioReducer
} from 'redux/reducers/index';
import { loadDarkTheme, saveDarkTheme } from 'services/localStorage';
import initialState from 'redux/reducers/initialState';

const configureStore = () => {
  const reducer = combineReducers({
    user: userReducer,
    layout: layoutReducer,
    profileId: profileReducer,
    seatsioIsOpen: seatsioReducer
  });

  const persistedState = {
    ...initialState,
    layout: {
      ...initialState.layout,
      isDarkTheme: loadDarkTheme()
    }
  };
  let middleware;
  if (process.env.NODE_ENV === 'development') {
    middleware = composeWithDevTools(applyMiddleware());
  } else {
    middleware = applyMiddleware();
  }
  return createStore(reducer, persistedState, middleware);
};

const store = configureStore();

store.subscribe(() => {
  saveDarkTheme(store.getState().layout);
});

export default store;
