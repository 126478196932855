import { MenuSwitchButton } from 'components/SwitchButton';
import DownIcon from 'mdi-react/ChevronDownIcon';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { Dispatch } from 'redux';
import { SetUserStateAction } from 'redux/actions/authActions';
import initialState from 'redux/reducers/initialState';
import { deleteTokens } from 'services/localStorage';
import { fetchCurrentUser } from 'services/userService';
import { LOGIN_ROUTE } from 'shared/routes';
import { State } from 'shared/types';
import { history } from '../../App/router';
import TopbarMenuOptions from './TopbarMenuOptions';

interface TopbarProfilProps {
  changeTheme: VoidFunction;
  logoutUser: VoidFunction;
  isDarkTheme: boolean;
}

const TopbarProfile = ({ changeTheme, logoutUser, isDarkTheme }: TopbarProfilProps) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const onLogoutClick = () => {
    deleteTokens();
    logoutUser();
    history.push(LOGIN_ROUTE);
  };

  const [username, setUsername] = useState('');

  useEffect(() => {
    async function getUsername(): Promise<void> {
      const user = await fetchCurrentUser();
      setUsername(user ? user.username : '');
    }
    getUsername();
  }, []);

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggle}>
        <span className="topbar__avatar-name">{username}<DownIcon className="topbar__icon" /></span>
      </button>
      {collapse && (
        <button type="button" className="topbar__back" onClick={toggle} />
      )}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <MenuSwitchButton label="Dark Theme" onClick={changeTheme} icon="moon" initialCheck={isDarkTheme} />
          <TopbarMenuOptions
            title="Log Out"
            icon="exit"
            onClick={onLogoutClick}
          />
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isDarkTheme: state.layout.isDarkTheme
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logoutUser: () => dispatch(SetUserStateAction(initialState.user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopbarProfile);
