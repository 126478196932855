import { SetProfileAction, SET_PROFILEID_ACTION } from '../actions/profileActions';
import initialState from './initialState';

export default function (state = initialState.profileId, action: SetProfileAction) {
  switch (action.type) {
    case SET_PROFILEID_ACTION:
      return action.profileId;
    default:
      return state;
  }
}
