import React from 'react';
import { connect } from 'react-redux';
import { State } from 'shared/types';
import LogInForm from './LogInForm';

interface LogIn {
  isAuthenticated: boolean;
  groups: string[];
}

const LogIn = ({
  isAuthenticated,
  groups
}: LogIn) => {
  return (
    <div className="account account--photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              Administration
              <span className="account__logo"> Lan ETS</span>
            </h3>
          </div>
          <LogInForm isAuthenticated={isAuthenticated} groups={groups} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isAuthenticated: state.user.isAuthenticated,
  groups: state.user.groups
});

export default connect(mapStateToProps)(LogIn);
