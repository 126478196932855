export const DEFAULT_ROUTE = '/';
export const LOGIN_ROUTE = '/login';

export const USERS_ROUTE = '/users';
export const USERID_ROUTE = '/users/:id';
export const TOURNAMENTS_ROUTE = '/tournaments';
export const INCIDENT_ROUTE = '/incident';
export const FLOORPLAN_ROUTE = '/floorplan';

export const CHECKOUT_ROUTE = '/checkout';
export const REGISTRATION_ROUTE = '/registration';
