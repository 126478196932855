import { toast } from 'react-toastify';

export const SEARCH_PLACEHOLDER = 'Search...';
export const DARK_THEME = 'theme-dark';
export type DARK_THEME = typeof DARK_THEME;
export const LIGHT_THEME = 'theme-light';
export type LIGHT_THEME = typeof LIGHT_THEME;

export const EquipmentTypes = [
  { value: 0, label: 'PC' },
  { value: 1, label: 'Laptop' },
  { value: 2, label: 'Monitor' },
  { value: 3, label: 'Console' },
  { value: 4, label: 'Peripherals' },
  { value: 5, label: 'Other' },
];

export const EquipmentTypesLabels = ['PC', 'Laptop', 'Monitor', 'Console', 'Peripherals', 'Other'];

export const TagStatus = ['Out', 'In', 'Banned'];

const ToastConfigs = {
  draggable: false,
  closeOnClick: true,
  position: toast.POSITION.TOP_RIGHT,
};

export const ToastConfigsSuccess = {
  ...ToastConfigs,
  autoClose: 5000,
  className: 'notification notification notification--success'
};

export const ToastConfigsError = {
  ...ToastConfigs,
  autoClose: 10000,
  className: 'notification notification notification--danger'
};
