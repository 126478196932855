import React from 'react';
import { User } from 'shared/types';
import { history } from '../../../App/router';

interface TableBody {
  users: User[];
}

const TableBody = ({ users }: TableBody) => (
  <tbody>
    {users.map((user) => (
      <tr
        key={user.id}
        onClick={() => {
          history.push(`/users/${user.id}`);
        }}
      >
        <td>{user.id}</td>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{user.fullname}</td>
      </tr>
    ))}
  </tbody>
);

export default TableBody;
