import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { AssignedEquipment } from 'shared/types';
import { EquipmentTypesLabels, TagStatus } from 'shared/constants';
import LabelTextField from 'components/LabelTextField';

interface EquipmentCard {
  equipment: AssignedEquipment;
}

const EquipmentCard = ({
  equipment
}: EquipmentCard) => {
  return (
    <Col md={3}>
      <Card>
        <CardBody>
          <div className="form form--horizontal">
            <LabelTextField
              label="QR Code"
              text={equipment.qrCode}
            />
            <LabelTextField
              label="Type"
              text={equipment.type !== null ? EquipmentTypesLabels[equipment.type] : ''}
            />
            <LabelTextField
              label="Status"
              text={TagStatus[equipment.status]}
            />
            <LabelTextField
              label="Modified"
              text={equipment.modified}
            />
            <LabelTextField
              label="Description"
              text={equipment.description}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EquipmentCard;
