import store from 'App/store';
import { SetUserStateAction } from 'redux/actions/authActions';
import services from '.';
import { TokenInfo, Tokens } from '../shared/types';
import { getTokens } from './localStorage';

export const fetchTokens = async (username: string, password: string) => {
  const response = await services.post('/accounts/api/v1/token/', { username, password });
  return response.data as Tokens;
};

export const parseJwt = (token: string): TokenInfo | null => {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  let tokenInfo;
  try {
    tokenInfo = JSON.parse(window.atob(base64)) as TokenInfo;
  } catch (err) {
    tokenInfo = null;
  }
  return tokenInfo;
};

export const isUserAuthenticated = () => {
  const tokens = getTokens();
  if (tokens) {
    const refresh = parseJwt(tokens.refresh);
    if (refresh && refresh.exp) {
      // Check expiration
    }
    store.dispatch(
      SetUserStateAction({
        isAuthenticated: true,
        groups: (refresh && refresh.groups) || []
      })
    );
  }
};

export const verifyToken = async (token: string) => {
  const response = await services.post('/accounts/api/v1/token/verify/', {
    token
  });
  return response.status === 200;
};

export const fetchAccessToken = async (refreshToken: string) => {
  const response = await services.post('/accounts/api/v1/token/refresh/', {
    refresh: refreshToken
  });

  return response.data.access as string;
};

export const hasAccess = (groups: string[]) => {
  return !!groups.find((g) => g === 'superuser' || g === 'staff' || g === 'cashier');
};

export const isCashier = (groups: string[]) => {
  return !!groups.find((g) => g === 'superuser' || g === 'staff' || g === 'cashier');
};

export const isStaff = (groups: string[]) => {
  return !!groups.find((g) => g === 'superuser' || g === 'staff');
};
