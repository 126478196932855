import {
  Field,
  Form,
  FormikProps,
  withFormik
} from 'formik';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import React, { useState, useEffect } from 'react';
import { fetchTokens, isUserAuthenticated } from 'services/authService';
import { deleteTokens, saveTokens } from 'services/localStorage';
import { DEFAULT_ROUTE } from 'shared/routes';
import { object, string } from 'yup';
import FormGroup from 'components/FormElements/FormGroup';
import { history } from '../../App/router';

interface LoginFormValues {
  username: string;
  password: string;
}

interface LogInForm {
  isAuthenticated: boolean;
  groups: string[];
}

const LOGIN_ERROR = 'Invalid username or password!';

const LogInForm = ({
  isSubmitting, status, setStatus, isAuthenticated, groups
}: FormikProps<LoginFormValues> & LogInForm) => {
  const [showPassword, setShowPassword] = useState(false);

  const displayPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isAuthenticated && groups.length === 0) {
      setStatus('User doesn\'t have access');
    } else {
      setStatus('');
    }
  }, [isAuthenticated, groups, setStatus]);

  return (
    <Form className="form">
      <FormGroup label="Username">
        <>
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="username"
            component="input"
            type="text"
            placeholder="Name"
          />
        </>
      </FormGroup>
      <FormGroup label="Password">
        <>
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
          />
          <button
            className={`form__form-group-button${
              showPassword ? ' active' : ''
            }`}
            onClick={displayPassword}
            type="button"
          >
            <EyeIcon />
          </button>
        </>
      </FormGroup>
      <span className="form__form-group-error">{status}</span>
      <button
        type="submit"
        disabled={isSubmitting}
        className="btn btn-primary account__btn account__btn--small"
      >
        Sign In
      </button>
    </Form>
  );
};

export default withFormik<LogInForm, LoginFormValues>({
  mapPropsToValues() {
    return {
      username: '',
      password: ''
    };
  },
  validationSchema: object().shape({
    username: string().required('Username required'),
    password: string().required('Password required')
  }),
  handleSubmit(
    values,
    { setSubmitting, setStatus }
  ) {
    fetchTokens(values.username, values.password).then((tokens) => {
      deleteTokens();
      saveTokens(tokens);
      setSubmitting(false);
      setStatus('');
      isUserAuthenticated();
      history.push(DEFAULT_ROUTE);
    }).catch((err) => {
      console.error(err);
      setSubmitting(false);
      setStatus(LOGIN_ERROR);
    });
  }
})(LogInForm);
