import React, { useEffect, useState } from 'react';
import {
  Form,
  withFormik,
  FormikProps
} from 'formik';
import {
  Button,
  ButtonToolbar,
  Modal,
} from 'reactstrap';
import { toast } from 'react-toastify';
import LabelInputField from 'components/LabelInputField';
import {
  ToastConfigsSuccess, ToastConfigsError
} from 'shared/constants';
import { CreateProfileFormValues, Profile } from 'shared/types';
import { createProfile } from 'services/userService';
import store from 'App/store';
import { setProfileIdAction } from 'redux/actions/profileActions';

interface CreateProfileForm {
  isOpen: boolean;
  closeModal: VoidFunction;
  theme: string;
  profile: Profile | null;
}
const PROFILE_CREATED = 'User created!';
const ERROR_PROFILE = 'Error creating the user!';

const CreateProfileForm = ({
  isOpen,
  closeModal,
  theme,
  resetForm,
  values,
  status,
  profile,
  isSubmitting
}: CreateProfileForm & FormikProps<CreateProfileFormValues>) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  useEffect(() => {
    setIsDisabled(!(values.email && values.username && values.firstname && values.lastname));
  }, [values]);

  useEffect(() => {
    if (!isSubmitting && isOpen && profile) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [isSubmitting, isOpen, profile]);

  return (
    <Modal isOpen={isOpen} toggle={handleClose} className={theme}>
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={handleClose}
        />
        <h4 className="bold-text  modal__title">Create User</h4>
      </div>
      <Form className="form form--horizontal">
        <LabelInputField
          name="firstname"
          label="First Name"
          component="input"
        />
        <LabelInputField
          name="lastname"
          label="Last Name"
          component="input"
        />
        <LabelInputField
          name="username"
          label="Username"
          component="input"
        />
        <LabelInputField
          name="email"
          label="Email"
          component="input"
          type="email"
        />
        <ButtonToolbar className="modal__footer">
          <Button className="confirm" color="primary" type="submit" disabled={isDisabled}>
            Save
          </Button>
          <Button className="cancel" onClick={handleClose}>Cancel</Button>{' '}
        </ButtonToolbar>
        <span className="form__form-group-error">{status}</span>
      </Form>
    </Modal>
  );
};

export default withFormik<CreateProfileForm, CreateProfileFormValues>({
  handleSubmit(values: CreateProfileFormValues, { setSubmitting, setStatus }) {
    createProfile(values).then((id: number) => {
      store.dispatch(setProfileIdAction(id.toString()));
      setSubmitting(false);
      toast.success(PROFILE_CREATED, ToastConfigsSuccess);
    }).catch((err) => {
      console.error(err);
      setStatus(ERROR_PROFILE);
      toast.success(ERROR_PROFILE, ToastConfigsError);
    });
  }
})(CreateProfileForm);
