import React from 'react';
import Select, { ActionMeta, ValueType } from 'react-select';

interface SelectField {
  value: OptionSelect;
  options: Array<OptionSelect>;
  name?: string;
  placeholder?: string;
  onChange: (value: ValueType<OptionSelect>, actionMeta: ActionMeta) => void;
}

interface OptionSelect {
  value: number;
  label: string;
}

const SelectField = ({
  value, name, placeholder, options, onChange
}: SelectField) => (
  <Select
    name={name}
    value={value}
    onChange={onChange}
    options={options}
    clearable={false}
    className="react-select"
    placeholder={placeholder}
    classNamePrefix="react-select"
  />
);

interface RenderSelectField {
  value: OptionSelect;
  onChange: (value: ValueType<OptionSelect>, actionMeta: ActionMeta) => void;
  options: Array<OptionSelect>;
  name?: string;
  placeholder?: string;
}

const RenderSelectField = ({
  value, options, onChange, placeholder, name,
}: RenderSelectField) => (
  <div className="form__form-group-input-wrap">
    <SelectField
      value={value}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  </div>
);

export default RenderSelectField;
