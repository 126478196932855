import React, { ReactChild } from 'react';
import { Table } from 'reactstrap';


interface BasicTable {
  children?: ReactChild;
  title?: string;
  hover?: boolean;
}

const BasicTable = ({
  children,
  title,
  hover
}: BasicTable) => {
  return (
    <>
      {title && (
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
        </div>
      )}
      <Table className="table--bordered" responsive hover={hover}>
        {children}
      </Table>
    </>
  );
};

export default BasicTable;
