export const SET_PROFILEID_ACTION = 'SET_PROFILEID_ACTION';
export type SET_PROFILEID_ACTION = typeof SET_PROFILEID_ACTION;

export interface SetProfileAction {
  type: SET_PROFILEID_ACTION;
  profileId: string;
}

export const setProfileIdAction = (profileId: string): SetProfileAction => ({
  type: SET_PROFILEID_ACTION,
  profileId
});
