import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { config } from 'config';
import { history } from 'App/router';
import store from 'App/store';
import { SetUserStateAction } from 'redux/actions/authActions';
import initialState from 'redux/reducers/initialState';
import { LOGIN_ROUTE } from 'shared/routes';
import { fetchAccessToken } from './authService';
import {
  deleteTokens,
  getAccessToken,
  getTokens,
  saveTokens
} from './localStorage';

const axiosRequest = axios.create({
  baseURL: config.API_URL
});

const BASE_REQUEST_CONFIG: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const getRequestHeaders = (
  fetchOptions?: AxiosRequestConfig
): AxiosRequestConfig => {
  const combinedOptions = { ...fetchOptions, ...BASE_REQUEST_CONFIG };

  combinedOptions.headers.Authorization = `Bearer ${getAccessToken()}`;

  return combinedOptions;
};

axiosRequest.interceptors.response.use(
  (response) => response,
  (err: AxiosError) => {
    return new Promise((resolve, reject) => {
      if (
        (err.config.url as string).includes('/accounts/api/v1/token/refresh/')
      ) {
        deleteTokens();
        store.dispatch(SetUserStateAction(initialState.user));
        history.push(LOGIN_ROUTE);
      }

      if (err?.response?.status === 401) {
        const tokens = getTokens();
        if (tokens && tokens.refresh) {
          const error = err;
          fetchAccessToken(tokens.refresh).then((access) => {
            saveTokens({ ...tokens, access });
            error.config.headers = { ...error.config.headers, Authorization: `Bearer ${access}` };
            resolve(axios.request(error.config));
          });
        } else {
          reject(err);
        }
      } else {
        reject(err);
      }
    });
  }
);


export default axiosRequest;
