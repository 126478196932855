import React from 'react';

const titles = ['Profile ID', 'Username', 'Email', 'Full Name'];


const TableHeader = () => (
  <thead>
    <tr>
      {titles.map((title) => (
        <th key={title}>{title}</th>
      ))}
    </tr>
  </thead>
);

export default TableHeader;
