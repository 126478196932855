import React from 'react';
import { Field } from 'formik';

interface LabelInputField {
  label: string;
  name: string;
  component: string;
  disabled?: boolean;
  type?: 'number' | 'text' | 'email';
}

const LabelInputField = ({
  label,
  name,
  component,
  disabled,
  type
}: LabelInputField) => {
  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{label}: </span>
      <div className="form__form-group-field">
        <Field
          name={name}
          component={component}
          disabled={disabled}
          type={type}
        />
      </div>
    </div>
  );
};

export default LabelInputField;
