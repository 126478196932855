import { UserState } from 'shared/types';

export const AUTH_ACTION = 'AUTH_ACTION';
export type AUTH_ACTION = typeof AUTH_ACTION;

export interface UserStateAction {
  type: AUTH_ACTION;
  data: UserState;
}

export const SetUserStateAction = (data: UserState): UserStateAction => ({
  type: AUTH_ACTION,
  data,
});
