import React, { useEffect, useState } from 'react';

interface SeatsioDesigner {
  className?: string;
  designerKey?: string;
  chartKey?: string;
  onRenderStarted?: any;
}

const CHARTJSURL = 'https://cdn.seatsio.net/chart.js';
const ID = 'chartDesigner';

const SeatsioDesigner = ({
  className,
  designerKey,
  chartKey,
  onRenderStarted
}: SeatsioDesigner) => {
  const config = {
    designerKey,
    chartKey,
    divId: ID,
    v2: true
  };
  const [chart, setChart] = useState<any>();

  const destroyChart = () => {
    if (chart && chart.state !== 'DESTROYED') {
      chart.destroy();
    }
  };

  const loadSeatsio = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = CHARTJSURL;
      script.onload = () => {
        // eslint-disable-next-line
        // @ts-ignore
        resolve(window.seatsio);
      };
      // eslint-disable-next-line
      script.onerror = () => reject(`Could not load ${script.src}`);
      document.head.appendChild(script);
    });
  };

  const getSeatsio = () => {
    return loadSeatsio();
  };

  const createChart = (seatsio: any) => {
    return new seatsio.SeatingChartDesigner(config);
  };

  const createAndRenderChart = async () => {
    await getSeatsio().then((seatsio) => {
      if (seatsio) setChart(createChart(seatsio).render());
      if (onRenderStarted) {
        onRenderStarted(chart);
      }
    });
  };

  useEffect(() => {
    createAndRenderChart();
    return destroyChart();
    // eslint-disable-next-line
  }, []);

  return (
    <div id={config.divId} className={className} />
  );
};

export default SeatsioDesigner;
