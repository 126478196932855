import React, { ReactChild } from 'react';

interface FormGroup {
  label?: string;
  children: ReactChild;
}

const FormGroup = ({
  label,
  children
}: FormGroup) => {
  return (
    <div className="form__form-group">
      {label && <span className="form__form-group-label">{label}: </span>}
      <div className="form__form-group-field">
        {children}
      </div>
    </div>
  );
};

export default FormGroup;
