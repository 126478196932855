import SearchBar from 'components/SearchBar';
import React from 'react';
import { SEARCH_PLACEHOLDER } from 'shared/constants';

interface UserSearchForm {
  onValueChange: (value: string) => void;
}

const UserSearchForm = ({ onValueChange }: UserSearchForm) => (
  <>
    <div className="card__title">
      <h5 className="bold-text">Search User</h5>
    </div>
    <div className="form">
      <SearchBar
        type="text"
        placeholder={SEARCH_PLACEHOLDER}
        onValueChange={onValueChange}
        withIcon
      />
    </div>
  </>
);

export default UserSearchForm;
