import React from 'react';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

interface TopbarSidebarButtonProps {
  changeMobileSidebarVisibility: VoidFunction;
  changeSidebarVisibility: VoidFunction;
}

const TopbarSidebarButton = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility
}: TopbarSidebarButtonProps) => (
  <div>
    <button
      type="button"
      className="topbar__button topbar__button--desktop"
      onClick={changeSidebarVisibility}
    >
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
    <button
      type="button"
      className="topbar__button topbar__button--mobile"
      onClick={changeMobileSidebarVisibility}
    >
      <img src={icon} alt="" className="topbar__button-icon" />
    </button>
  </div>
);

export default TopbarSidebarButton;
