import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from 'reactstrap';
import { fetchUsers } from 'services/userService';
import { User } from 'shared/types';
import Table from 'components/Table';
import TableHeader from './UserTable/TableHeader';
import TableBody from './UserTable/TableBody';
import UserSearchForm from './UserSearch';

const UserSearchPage = () => {
  const [value, setValue] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    let didCancel = false;
    async function getUsers() {
      const userArray = await fetchUsers(value);

      if (!didCancel) {
        setUsers(userArray);
      }
    }
    getUsers();
    return () => {
      didCancel = true;
    };
  }, [value]);

  const onValueChange = (input: string) => {
    setValue(input);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Users</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <UserSearchForm onValueChange={onValueChange} />
              <Table hover>
                <>
                  <TableHeader />
                  <TableBody users={users} />
                </>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserSearchPage;
