export const SET_SEATSIOISOPEN_ACTION = 'SET_SEATSIOISOPEN_ACTION';
export type SET_SEATSIOISOPEN_ACTION = typeof SET_SEATSIOISOPEN_ACTION;

export interface SeatsioIsOpenAction {
  type: SET_SEATSIOISOPEN_ACTION;
}

export const setSeatsioIsOpenAction = (): SeatsioIsOpenAction => ({
  type: SET_SEATSIOISOPEN_ACTION
});
