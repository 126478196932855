import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Col,
  Row,
  Button
} from 'reactstrap';
import { SeatsioIsOpenAction, setSeatsioIsOpenAction } from 'redux/actions/seatsioActions';

interface FloorplanPage {
  openSeatsioChartDesigner: VoidFunction;
}

const FloorplanPage = ({
  openSeatsioChartDesigner
}: FloorplanPage) => {
  useEffect(() => {
    openSeatsioChartDesigner();
  }, [openSeatsioChartDesigner]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Floorplan</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="primary" size="lg" onClick={openSeatsioChartDesigner}>
            Open Char Designer
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<SeatsioIsOpenAction>) => ({
  openSeatsioChartDesigner: () => dispatch(setSeatsioIsOpenAction())
});

export default connect(null, mapDispatchToProps)(FloorplanPage);
