import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_ROUTE } from 'shared/routes';
import TopbarProfile from './TopbarProfile';
import TopbarSidebarButton from './TopbarSidebarButton';

interface TopbarProps {
  changeMobileSidebarVisibility: VoidFunction;
  changeSidebarVisibility: VoidFunction;
  changeTheme: VoidFunction;
}

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  changeTheme
}: TopbarProps) => (
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to={DEFAULT_ROUTE} />
      </div>
      <div className="topbar__right">
        <TopbarProfile changeTheme={changeTheme} />
      </div>
    </div>
  </div>
);

export default Topbar;
