import React, { ReactChild } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DARK_THEME, LIGHT_THEME } from 'shared/constants';
import { State } from 'shared/types';

interface MainWrapperProps {
  isDarkTheme: boolean;
  children: ReactChild;
}

const MainWrapper = ({ isDarkTheme, children }: MainWrapperProps) => {
  const theme = isDarkTheme ? DARK_THEME : LIGHT_THEME;
  const wrapperClass = classNames({
    wrapper: true,
    'blocks-with-shadow-theme': true
  });
  return (
    <div className={theme}>
      <div className={wrapperClass}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  isDarkTheme: state.layout.isDarkTheme
});

export default connect(mapStateToProps)(MainWrapper);
