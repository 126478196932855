import { State } from 'shared/types';

const initialState: State = {
  user: {
    isAuthenticated: false,
    groups: []
  },
  layout: {
    show: false,
    collapse: false,
    isDarkTheme: false
  },
  profileId: '',
  seatsioIsOpen: false
};
export default initialState;
