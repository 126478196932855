import React from 'react';


interface LabelTextField {
  label: string;
  text: string;
}

const LabelTextField = ({
  label,
  text
}: LabelTextField) => {
  return (
    <div className="form__form-group">
      <span className="form__form-group-label">{label}: </span>
      <div className="form__form-group-field">
        <p className="form__form-group-span"> {text}</p>
      </div>
    </div>
  );
};

export default LabelTextField;
