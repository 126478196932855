import React from 'react';

interface TopbarMenuOptionsProps {
  title: string;
  onClick: VoidFunction;
  icon?: string;
}

const TopbarMenuOptions = ({
  title,
  icon,
  onClick
}: TopbarMenuOptionsProps) => (
  <div className="topbar__link" onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <span className="topbar__link-title">{title}</span>
  </div>
);
export default TopbarMenuOptions;
