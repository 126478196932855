import React, { useState } from 'react';

interface SwitchButton {
  initialCheck?: boolean;
  label?: string;
  label2?: string;
  name?: string;
  icon?: string;
  onChange?: VoidFunction;
  onClick?: VoidFunction;
  style?: React.CSSProperties;
}

const SwitchButton = ({
  onChange,
  initialCheck,
  onClick,
  label,
  label2,
  name,
  icon,
  style
}: SwitchButton) => {
  const [isChecked, setIsChecked] = useState(!!initialCheck);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (onClick) {
      onClick();
    }
  };

  const handleOnChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className="toggle-btn customizer__toggle" onClick={handleClick} style={style}>
      <span className={`topbar__link-icon lnr lnr-${icon}`} />
      <span className="topbar__link-title" style={{ paddingRight: '5px' }}>{label}</span>
      <input
        className="toggle-btn__input"
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={() => handleOnChange()}
      />
      <button type="button" className="toggle-btn__input-label" />
      <span className="topbar__link-title" style={{ paddingLeft: '5px', lineHeight: '23px' }}>{label2}</span>
    </div>
  );
};


export const MenuSwitchButton = ({
  onChange,
  initialCheck,
  onClick,
  label,
  name,
  icon
}: SwitchButton) => {
  const [isChecked, setIsChecked] = useState(!!initialCheck);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (onClick) {
      onClick();
    }
  };

  const handleOnChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className="topbar__link" onClick={handleClick}>
      <div className="toggle-btn customizer__toggle">
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{label}</p>
        <input
          className="toggle-btn__input"
          type="checkbox"
          name={name}
          checked={isChecked}
          onChange={() => handleOnChange()}
        />
        <button type="button" className="toggle-btn__input-label" />
      </div>
    </div>
  );
};

export default SwitchButton;
